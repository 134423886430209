import * as React from "react";
import * as styles from "./layout.module.scss";
import classnames from "classnames";

type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  id?: string;
};

export const Layout = ({ children, className }: ContainerProps) => (
  <div className={classnames(styles.layout, classnames)}>{children}</div>
);

export const PageStyles = {
  padded: styles.padded,
  solid: styles.solid,
};

export const Page = ({
  children,
  className,
  contentClassName,
  id,
}: ContainerProps & { contentClassName?: string }) => (
  <section id={id} className={classnames(styles.page, className)}>
    <div className={classnames(contentClassName, styles.content)}>
      {children}
    </div>
  </section>
);

export const Background = ({ children, className }: ContainerProps) => (
  <div className={classnames(styles.background, className)}>{children}</div>
);

export const Subsection = ({ children, className }: ContainerProps) => (
  <div className={classnames(styles.subsection, className)}>{children}</div>
);

export const LinkButtonContainer = ({
  children,
  className,
}: ContainerProps) => (
  <div className={classnames(styles.linkButtonContainer, className)}>
    {children}
  </div>
);

export const FormFrame = ({ children, className }: ContainerProps) => (
  <div className={classnames(styles.formFrame, className)}>{children}</div>
);

export const FormFrameHead = ({
  onClickX,
  className,
}: { onClickX?: () => unknown } & ContainerProps) => (
  <div className={classnames(styles.formFrameHead, className)}>
    <div role="button" onClick={() => onClickX?.()}>
      🗙
    </div>
  </div>
);

export const FormGroup = ({ children, className }: ContainerProps) => (
  <div className={classnames(styles.formGroup, className)}>{children}</div>
);

export const OtherStyles = {
  linkButton: styles.linkButton,
};

export const Checkbox = (props: {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  onClick: () => unknown;
}) => (
  <div className={styles.checkboxWrapper}>
    <div
      className={classnames(styles.checkbox, props.disabled && styles.disabled)}
      role="checkbox"
      onClick={(e) => {
        e.preventDefault();
        if (!props.disabled) {
          props.onClick();
        }
      }}
    >
      {!!props.checked && "✔"}
    </div>
    {props.label}
  </div>
);
