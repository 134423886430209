// extracted by mini-css-extract-plugin
export var background = "layout-module--background--TSS-q";
export var checkbox = "layout-module--checkbox--RZpCz";
export var checkboxWrapper = "layout-module--checkboxWrapper--EuveS";
export var content = "layout-module--content--FwTjf";
export var disabled = "layout-module--disabled--uvPoE";
export var formFrame = "layout-module--formFrame--Kzz7F";
export var formFrameHead = "layout-module--formFrameHead--LgtL8";
export var formGroup = "layout-module--formGroup--f+EOs";
export var layout = "layout-module--layout--Jg7Rt";
export var linkButton = "layout-module--linkButton--6ELWu";
export var linkButtonContainer = "layout-module--linkButtonContainer--JudTO";
export var padded = "layout-module--padded--wNUL7";
export var page = "layout-module--page--ch2c9";
export var solid = "layout-module--solid--UBkfg";
export var subsection = "layout-module--subsection--3hjfI";