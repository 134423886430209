import classnames from "classnames";
import { Link } from "gatsby";
import React from "react";
import {
  Background,
  Layout,
  Page,
  PageStyles,
  Subsection,
} from "../components/layout";
import { Typography } from "../components/typography";
import * as styles from "./index.module.scss";
import { useLocation } from "@reach/router";

export const GiftPage = () => {
  const location = useLocation();
  return (
    <Page
      id="gifts-section"
      className={classnames(PageStyles.solid, PageStyles.padded)}
    >
      <Subsection>
        <Link className={styles.backLink} to={"/" + location.search}>
          <span className={styles.arrow}>←</span>{" "}
          <span className={styles.text}>Back to main page</span>
        </Link>
        <h2
          className={classnames(styles.boldedSubheading, Typography.fontPrata)}
        >
          Gifts
        </h2>
        <div>
          <p>
            Your presence is more than enough! But if you want to help us
            prepare for our new life together, we've set up an online cash
            registry.
          </p>
          <p>
            You've reached our cash-registry page. Thanks in advance for the
            gift! Kindly end your cash gift with the number 16 at the end so its
            easier for us to track your gift.
          </p>
          <p>For example: Rp. 200,000 became Rp. 200,016.</p>
          <p>
            You can also send a confirmation to us, by sending an email to:
            ALMONKINDLE@GMAIL.COM
          </p>
        </div>
      </Subsection>
      <Subsection className={styles.accountsSection}>
        <h3 className={Typography.fontPrata}>INDONESIAN ACCOUNTS:</h3>
        <div className={styles.account}>
          <div>MONIC ANDYANI</div>
          <div>BANK: Bank Central Asia (BCA)</div>
          <div>ACCOUNT NUMBER: 8915188032</div>
        </div>
        <div className={styles.account}>
          <div>MONIC ANDYANI</div>
          <div>BANK: Bank Negara Indonesia (BNI)</div>
          <div>ACCOUNT NUMBER: 760651571</div>
        </div>
      </Subsection>
      <Subsection className={styles.accountsSection}>
        <h3 className={Typography.fontPrata}>WISE.COM ACCOUNT:</h3>
        <div className={styles.account}>
          <div>ALAN DARMASAPUTRA</div>
          <div>BANK: Bank Tabungan Pensiunan Nasional (BTPN)</div>
          <div>ACCOUNT NUMBER: 90012844952</div>
        </div>
      </Subsection>
    </Page>
  );
};

export default () => (
  <main className={styles.main}>
    <Background />
    <Layout>
      <GiftPage />
    </Layout>
  </main>
);
